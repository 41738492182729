<template>
  <div>
    <b-row>
      <b-col
        cols="12"
        md="8"
      >
        <b-card
          :title="$t('Edit Owwner')"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.applicant"
                  class="mt-2"
                  :state="errors.name ?false:null"
                  :placeholder="$t('applicant')"
                />
                <small
                  v-if="errors.applicant"
                  class="text-danger"
                >{{ errors.applicant[0] }}</small>
                <label>{{ $t('applicant') }}</label>
              </div>
            </b-col>

            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.owner"
                  class="mt-2"
                  :state="errors.owner ?false:null"
                  :placeholder="$t('owwner')"
                />
                <small
                  v-if="errors.owner"
                  class="text-danger"
                >{{ errors.owner[0] }}</small>
                <label>{{ $t('owner') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >

              <div class="form-label-group">
                <b-form-input
                  v-model="data.phone"
                  class="mt-2"
                  :state="errors.phone ?false:null"
                  :placeholder="$t('Phone')"
                />
                <small
                  v-if="errors.phone"
                  class="text-danger"
                >{{ errors.phone[0] }}</small>
                <label>{{ $t('Phone') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('date_time') }}</label>
                <b-form-datepicker
                  v-model="data.date_time"
                  placeholder="date_time"
                  :state="errors.date_time ?false:null"
                />
                <small
                  v-if="errors.date_time"
                  class="text-danger"
                >{{ errors.date_time[0] }}</small>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div class="form-label-group">
                <b-form-input
                  v-model="data.identify"
                  class="mt-2"
                  :state="errors.identify ?false:null"
                  :placeholder="$t('Identify')"
                />
                <small
                  v-if="errors.identify"
                  class="text-danger"
                >{{ errors.identify[0] }}</small>
                <label>{{ $t('Identify') }}</label>
              </div>
            </b-col>
            <b-col
              cols="12"
              md="6"
            >
              <div>
                <label>{{ $t('Identification_type') }}</label>
                <v-select
                  v-model="data.identification_type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :reduce="types=>types.key"
                  :options="types"
                />
                <small
                  v-if="errors.identification_type"
                  class="text-danger"
                >{{ errors.identification_type[0] }}</small>
              </div>
            </b-col>

          </b-row>
        </b-card>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-row>

          <b-col cols="12">
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                >
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-success"
                    @click="update"
                  >
                    {{ $t('Update') }}
                  </b-button>
                  <b-button
                    class="w-100 mb-1"
                    variant="gradient-danger"
                    @click="data={},errors=[]"
                  >
                    {{ $t('Reset Form') }}
                  </b-button>
                  <b-button
                    class="w-100 "
                    variant="gradient-primary"
                    @click="print()"
                  >
                    {{ $t('Print') }}
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
            <b-card
              id="printMe"
              style="visibility: hidden"
            >
              <div style="width: 100%!important; height: 100%; background-color: white; position: relative; top: 0;right: 0;left: 0;bottom: 0;">
                <img
                  src="@/assets/images/logo/logo.jpg"
                  style="width: 80px;
            height: 86px;
position: fixed;top:10px;left: 10px;"
                >
                <h3 style=" color: black;position: fixed;top:100px;left: 10px; ">
                  {{ this.data.date_time }} : التاريخ
                </h3>
              </div>

              <h1
                style="position: fixed;
                top: 10px;
                right: 10px;
                text-align: right;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >حكومة الإنقاذ السورية<br> المؤسسة العامة للنقل
              </h1>
              <h1
                style="position: fixed;
                top: 120px;
                right: 50%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >رقم الطلب<br>{{ this.data.id }}
              </h1>
              <h2
                style="position: fixed;
                top: 240px;
                right: 5%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >المالك : {{ this.data.owner }}
              </h2>
              <h2
                style="position: fixed;
                top: 290px;
                right: 5%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >مقدم الطلب : {{ this.data.applicant }}
              </h2>
              <h2
                style="position: fixed;
                top: 340px;
                right: 5%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >الهاتف : {{ this.data.phone }}
              </h2>
              <h2
                style="position: fixed;
                top: 390px;
                right: 5%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >الموعد : {{ this.data.date_time }}
              </h2>
              <h2
                style="position: fixed;
                top: 440px;
                right: 5%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >رقم الإثبات الشخصي : {{ this.data.identify }}
              </h2>
              <h2
                style="position: fixed;
                top: 490px;
                right: 5%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >نوع الاإثبات الشخصي : {{ $t( this.data.identification_type ) }}
              </h2>
              <h2
                style="position: fixed;
                top: 540px;
                right: 5%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >العنوان الدائم : {{ this.data.adress }}
              </h2>
              <h2
                style="position: fixed;
                top: 590px;
                right: 5%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >العنوان الحالي : {{ this.data.currentAdres }}
              </h2>
              <h2
                style="position: fixed;
                top: 640px;
                right: 5%;
                text-align: center;
               color: black; font-weight: bold;
                font-family: 'Abdoullah Ashgar EL-kharef';"
              >محل ورقم القيد : {{ this.data.placeNo }}
              </h2>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {},
      errors: [],
      fileErrors: '',

    }
  },
  computed: {

    types() {
      return [{ key: 'identify', title: this.$t('identifyType') }, { key: 'passport', title: this.$t('passport') }, { key: 'other', title: this.$t('other') }]
    },
    Element() { return this.$store.getters['owwner/GetElement'] },
  },
  created() {
    this.getData()
  },
  methods: {
    async print() {
      // Pass the element id here
      await this.$htmlToPaper('printMe')
    },
    setBoolean(val) {
      if (this.data[val] === undefined) { this.data[val] = false }
    },
    UpdateFileError(variable) {
      this.fileErrors = variable
    },
    getData() {
      this.$store.dispatch('owwner/GetElement', this.$route.params.owwner).then(() => {
        this.data = this.Element
      })
    },
    update() {
      this.errors = []
      this.$store.dispatch('owwner/Update', this.data).then(() => {
      }).catch(error => {
        this.errors = error.response.data.errors
      })
    },
  },
}
</script>
